@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 100 300;
  src: url('/fonts/Lato-Light.woff2') format('woff2');
  font-display: swap;
}
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 100 300;
  src: url('/fonts/Lato-LightItalic.woff2') format('woff2');
  font-display: swap;
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/Lato-Regular.woff2') format('woff2');
  font-display: swap;
}
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  src: url('/fonts/Lato-RegularItalic.woff2') format('woff2');
  font-display: swap;
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500 700;
  src: url('/fonts/Lato-Bold.woff2') format('woff2');
  font-display: swap;
}
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 500 700;
  src: url('/fonts/Lato-BoldItalic.woff2') format('woff2');
  font-display: swap;
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 800 900;
  src: url('/fonts/Lato-Black.woff2') format('woff2');
  font-display: swap;
}
@font-face {
  font-family: 'Soleil';
  font-style: normal;
  font-weight: 100 600;
  src: url('/fonts/Soleil-Regular.otf') format('opentype');
  font-display: swap;
}
@font-face {
  font-family: 'Soleil';
  font-style: normal;
  font-weight: 700 900;
  src: url('/fonts/Soleil-Bold.otf') format('opentype');
  font-display: swap;
}
@font-face {
  font-family: 'Effra';
  font-style: normal;
  src: url('/fonts/Effra.ttf') format('truetype');
  font-display: swap;
}
